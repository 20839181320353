import './onLoad';
import './aos-init';
import {onLoad} from "./onLoad";

onLoad(() => {
    const importMobileFunctions = () => {
        import('../plugins/mobile').then(module => {
            module.default();
        });
    }

    let wasImported = false;

    const checkMobile = () => {
        if(wasImported) return;

        if (window.innerWidth < 992) {
            importMobileFunctions();
            wasImported = true;
        }
    }

    window.addEventListener('resize', function handler(e) {
        if(wasImported) {
            window.removeEventListener('resize', handler);
        }

        checkMobile();
    });

    checkMobile();
});
import {onLoad} from "../base/onLoad";

onLoad(() => {
    const triggers = document.querySelectorAll('[data-open-modal]');

    triggers.forEach(item => {
        item.addEventListener('click', () => {
            const modalId = item.getAttribute('data-open-modal');
            const modal = document.querySelector(`#${modalId}`);
            if(modal) {
                modal.classList.toggle('open');
            }
        });
    });
});s
import {onLoad} from "../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
   const element = document.querySelector('.recensies-slider');

   if(!element) {
      return;
   }

   const splide = new Splide(element, {
      type: 'loop',
      padding: '100px',
      gap: '100px',
      pagination: false,
      autoplay: true,

      breakpoints: {
         768: {
            padding: '15px',
            gap: '15px',
         }
      }
   });

   splide.mount();
});
import {onLoad} from "../base/onLoad";

onLoad(() => {
    const elements = document.querySelectorAll('[data-has-scrolled]');
    elements.forEach(i => new HasScrolled(i));
});

class HasScrolled {
    /**
     * @param {HTMLElement} element
     * @param element
     */
    constructor(element) {
        this.element = element;

        this.initElement();
        this.initListeners();
    }

    initElement() {
        this.element.setAttribute('data-has-scrolled', 'false');
    }

    initListeners() {
        window.addEventListener('scroll', this.onScroll.bind(this));
        this.onScroll();
    }

    onScroll() {
        if (window.scrollY > 0) {
            this.element.setAttribute('data-has-scrolled', 'true');
        } else {
            this.element.setAttribute('data-has-scrolled', 'false');
        }
    }
}
//data-src.js
import {onLoad} from "../base/onLoad";

onLoad(() => {
    const elements = document.querySelectorAll('[data-src]');
    window.addEventListener('scroll', function handler(e) {
        window.removeEventListener('scroll', handler);
        console.log('lazyloading');
        elements.forEach(i => new DataSrc(i));
    });
});

class DataSrc {
    constructor(element) {
        this.element = element;
        this.src = this.element.getAttribute('data-src');
        this.element.src = this.src;
        this.element.removeAttribute('data-src');
    }
}
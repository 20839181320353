import {onLoad} from "../base/onLoad";

onLoad(() => {
    const gfields = document.querySelectorAll('.gfield');
    gfields.forEach(i => new Gfield(i));
});

class Gfield {
    /**
     * @param {HTMLElement} element
     * @param element
     */
    constructor(element) {
        this.element = element;

        this.input = this.getInput();

        if (this.input) {
            this.initElement();
            this.initListeners();
        }
    }

    initElement() {
        const classes = 'gfield-has-floating-label';
        this.element.classList.add(classes);
    }

    initListeners() {
        this.input.addEventListener('focus', this.onFocus.bind(this));
        this.input.addEventListener('blur', this.onChange.bind(this));
        this.input.addEventListener('change', this.onChange.bind(this));
        this.onChange();
    }

    setActive() {
        this.element.classList.add('gfield--active');
    }

    setInactive() {
        this.element.classList.remove('gfield--active');
    }

    onFocus() {
        this.setActive();
    }

    onChange() {
        if (this.input.value) {
            this.setActive();
        } else {
            this.setInactive();
        }
    }

    getInput() {
        const element =  this.element.querySelector('input, textarea, select');

        const type = element.getAttribute('type');
        if(type === 'checkbox') {
            return false;
        }

        return element;
    }
}